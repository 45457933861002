import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import config from '../config';
import notificationService from '../services/notificationSV';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [csrfToken, setCsrfToken] = useState(null);
  const [error, setError] = useState(null);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const wsConnectedRef = useRef(false);

  const updateLastActivity = useCallback(() => {
    setLastActivity(Date.now());
  }, []);

  const connectWebSocket = useCallback(async (userId) => {
    if (!wsConnectedRef.current) {
      try {
        await notificationService.connect(userId, 'teacher');
        wsConnectedRef.current = true;
      } catch (error) {
        console.error('WebSocket连接失败:', error);
      }
    }
  }, []);

  const checkAuth = useCallback(async () => {
    if (loading) {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/check_auth.php`, { withCredentials: true });
        if (response.data.authenticated) {
          setUser(response.data.user);
          setCsrfToken(response.data.csrfToken);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('csrfToken', response.data.csrfToken);
          await connectWebSocket(response.data.user.id);
        } else {
          clearUserState();
        }
      } catch (error) {
        console.error('Authentication check failed:', error);
        clearUserState();
      } finally {
        setLoading(false);
      }
    }
  }, [connectWebSocket, loading]);

  useEffect(() => {
    checkAuth();
    return () => {
      notificationService.disconnect();
      wsConnectedRef.current = false;
    };
  }, [checkAuth]);

  useEffect(() => {
    const checkInactivity = setInterval(() => {
      const currentTime = Date.now();
      const inactiveTime = currentTime - lastActivity;
      if (inactiveTime > 55 * 60 * 1000) {
        logout();
      }
    }, 60000);

    return () => clearInterval(checkInactivity);
  }, [lastActivity]);

  useEffect(() => {
    const events = ['mousedown', 'keydown', 'scroll', 'touchstart'];
    const handleActivity = () => updateLastActivity();

    events.forEach(event => window.addEventListener(event, handleActivity));

    return () => {
      events.forEach(event => window.removeEventListener(event, handleActivity));
    };
  }, [updateLastActivity]);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/login.php`, { email, password }, { withCredentials: true });
      if (response.data.success) {
        setUser(response.data.user);
        setCsrfToken(response.data.csrfToken);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('csrfToken', response.data.csrfToken);
        await connectWebSocket(response.data.user.id);
        return { success: true };
      }
      return { success: false, message: response.data.message };
    } catch (error) {
      console.error('Login failed:', error);
      return { success: false, message: 'An error occurred during login.' };
    }
  };

  const logout = useCallback(() => {
    clearUserState();
    notificationService.disconnect();
    wsConnectedRef.current = false;
    window.location.href = '/login';
  }, []);

  const clearUserState = useCallback(() => {
    setUser(null);
    setCsrfToken(null);
    localStorage.removeItem('user');
    localStorage.removeItem('csrfToken');
  }, []);

  const axiosWithCsrf = axios.create({
    withCredentials: true,
    baseURL: config.API_BASE_URL,
  });

  axiosWithCsrf.interceptors.request.use(config => {
    if (csrfToken) {
      config.headers['X-CSRF-Token'] = csrfToken;
    }
    return config;
  });

  const value = {
    user,
    loading,
    error,
    login,
    logout,
    updateLastActivity,
    axiosWithCsrf,
    wsConnected: wsConnectedRef.current
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;