import config from '../config';  // 确保路径正确

export const loadAvatarImage = async (userId, userName) => {
  const baseUrl = config.AVATAR_BASE_URL || 'http://localhost/uploads/teacher_avatars';
  const formats = ['jpg', 'jpeg', 'png', 'gif'];
  const timestamp = Date.now(); // 添加时间戳
  
  for (let format of formats) {
    try {
      const response = await fetch(`${baseUrl}/${userId}.${format}?t=${timestamp}`);
      if (response.ok) {
        return `${baseUrl}/${userId}.${format}?t=${timestamp}`;
      }
    } catch (error) {
      console.error('Error loading avatar:', error);
    }
  }
  
  // 如果所有格式都失败，返回基于用户名的默认头像
  return generateDefaultAvatar(userName);
};

const generateDefaultAvatar = (userName) => {
  const initials = userName
    ? userName.split(' ').map(name => name[0]).join('').toUpperCase().slice(0, 3)
    : 'U';

  return `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <rect width="100" height="100" fill="#007bff"/>
    <text x="50" y="50" font-family="Arial" font-size="40" fill="white" text-anchor="middle" dy=".3em">${initials}</text>
  </svg>`;
};